import { CarrierNetworkFrontendClient } from "@deliverr/carrier-network-fe-client";
import { AuthEngine } from "@deliverr/core-auth";
import { InboundClient } from "@deliverr/legacy-inbound-client";
import { NonComplianceClient } from "@deliverr/non-compliance-client";
import { SortationApiClient } from "@deliverr/sortation-client";
import { ParcelClient } from "@deliverr/parcel-client";
import { log } from "utils/logger";
import { LabelaryClient } from "./LabelaryClient/LabelaryClient";
import { InboundVisibilityClient } from "@deliverr/inbound-visibility-client";

export interface ClientApiWithTokenFn {
  setTokenFn: (tokenFn: () => Promise<string>) => void;
}

export interface ClientApiLegacy {
  setTokenFn: (tokenFn: () => Promise<string>) => void;
  withAccessToken: (token: string) => void;
  addBeforeEach: (fn: () => Promise<void>) => void;
}

export type ClientApi = ClientApiWithTokenFn | ClientApiLegacy;

export const setTokenFunctionInClients = (clientsArray: ClientApi[], accessToken: Promise<string>) => {
  clientsArray.forEach((client: ClientApi) => {
    if ((client as any).withAccessToken) {
      const clientCasted = client as ClientApiLegacy;
      clientCasted.addBeforeEach(async () => {
        const token = await accessToken;
        clientCasted.withAccessToken(token);
      });
    } else if ((client as any).setTokenFn) {
      const clientCasted = client as ClientApiWithTokenFn;
      clientCasted.setTokenFn(() => accessToken);
    } else {
      log(
        { fn: "setTokenFunctionInClients", client },
        "Unexpected error with client type contract. Client does not have a setTokenFn or withAccessToken method."
      );
    }
  });
};

export const inboundClient = new InboundClient({
  baseURL: process.env.REACT_APP_INBOUND_SERVICE_URL!,
  authEngine: AuthEngine.AUTH0,
});

export const sortationApiClient = new SortationApiClient({
  baseURL: `${process.env.REACT_APP_SORTATION_URL!}/api`,
  authEngine: AuthEngine.AUTH0,
});

export const nonComplianceClient = new NonComplianceClient({
  serviceUrlEnvKey: "REACT_APP_NON_COMPLIANCE_SERVICE_URL",
  authEngine: AuthEngine.AUTH0,
});

export const parcelClient = new ParcelClient({
  baseURL: process.env.REACT_APP_PARCEL_URL!,
  authEngine: AuthEngine.AUTH0,
});

export const labelaryClient = new LabelaryClient();

export const carrierNetworkClient = new CarrierNetworkFrontendClient(
  process.env.REACT_APP_CARRIER_NETWORK_FRONTEND_URL!
);

export const inboundVisibilityClient = new InboundVisibilityClient({
  serviceUrlEnvKey: "REACT_APP_INBOUND_VISIBILITY_SERVICE_URL",
  authEngine: AuthEngine.AUTH0,
});
