export enum Path {
  root = "/",

  // Inbound Paths
  inboundsList = "inbounds/*",
  receiveAttribution = "inbounds/receive-attribution",
  updateAsnPackageLink = "inbounds/update-asn-package-link",
  asnAssignmentTool = "inbounds/asn-assignment-tool",
  eventAsnSplitTool = "inbounds/event-asn-split-tool",
  boxAssignmentTool = "inbounds/box-assignment-tool",
  manageReceivingZones = "inbounds/manage-receiving-zones",
  shipmentCompletionTool = "inbounds/shipment-completion",
  lostBoxesTool = "inbounds/lost-boxes-tool",

  // Sortation Paths
  sortationList = "sortation/*",
  sortCodeManager = "sortation/sort-code-manager",
  sortCodeSelector = "sortation/sort-code-manager/:sortationCenterId/:shippingMethod",
  sortCodeEditor = "sortation/sort-code-manager/:sortationCenterId/:shippingMethod/:sortCode",
  dockDoorLabels = "sortation/dock-door-labels",

  // Non Compliance Paths
  issueList = "non-compliance/issues/*",
  issueCancellationTool = "non-compliance/issues/issue-cancellation-tool",
  unlabeledBoxTool = "non-compliance/issues/unlabeled-box-tool",

  issueActionTool = "non-compliance/issues/issue-action-tool",

  // Carrier Network Paths
  carrierNetworkList = "carrier-network/*",
  carrierNetworkLanes = "carrier-network/lanes",
  carrierNetworkLaneDetails = "/carrier-network/lanes/:laneId",
  carrierNetworkNodeDetails = "/carrier-network/nodes/:nodeId",

  // Parcel Paths
  parcelList = "parcel/*",
  parcelNetCHBProcess = "parcel/process-netchb-results",
}

export const INBOUND_PATHS = [
  Path.inboundsList,
  Path.receiveAttribution,
  Path.updateAsnPackageLink,
  Path.asnAssignmentTool,
  Path.boxAssignmentTool,
  Path.manageReceivingZones,
  Path.eventAsnSplitTool,
  Path.shipmentCompletionTool,
  Path.lostBoxesTool,
];

export const SORTATION_PATHS = [Path.sortationList, Path.sortCodeManager];

export const NON_COMPLIANCE_PATHS = [
  Path.issueList,
  Path.issueCancellationTool,
  Path.unlabeledBoxTool,
  Path.issueActionTool,
];

export const PARCEL_PATHS = [Path.parcelList, Path.parcelNetCHBProcess];

export const CARRIER_NETWORK_PATHS = [
  Path.carrierNetworkList,
  Path.carrierNetworkLanes,
  Path.carrierNetworkLaneDetails,
  Path.carrierNetworkNodeDetails,
];
