import { useState } from "react";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useForm, useController, SubmitHandler } from "react-hook-form";
import { logError } from "utils/logger";
import { handleInboundVisibilityError } from "utils/handleInboundVisibilityError";

type FormValues = {
  cdSkus: string;
};

export const useLostBoxesTool = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({});
  const { inboundVisibilityClient } = useClientsWithAuth(); // Assuming this client exists

  const cdSkusController = useController({
    name: "cdSkus",
    control,
    rules: {
      required: "CDSKUs are required",
    },
    defaultValue: "",
  });

  const onSubmitLostBoxesTool: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsSubmitting(true);
      const cdSkusInput = data.cdSkus;
      // Split and clean the input into an array of boxLabels
      const boxLabels = cdSkusInput
        .split(",")
        .map((sku) => sku.trim())
        .filter((sku) => sku !== "");

      if (boxLabels.length === 0) {
        toast.error("Please enter at least one valid CDSKU", { position: "top-right" });
        setIsSubmitting(false);
        return;
      }

      // Prepare payload
      const payload = { boxLabels };

      // Call the API
      await inboundVisibilityClient.createLostBoxes(payload);
      toast.success("Submission successful", { position: "top-right" });
      resetAll();
    } catch (error) {
      handleInboundVisibilityError({ fn: "useLostBoxesTool.onSubmitLostBoxesTool" }, error as Error);
    } finally {
      setIsSubmitting(false);
    }
  };

  function resetAll() {
    reset();
    setIsSubmitting(false);
  }

  return {
    onSubmitLostBoxesTool: handleSubmit(onSubmitLostBoxesTool),
    cdSkusController,
    resetAll,
    isSubmitting,
  };
};
