import { Button, Card, ThemeProps, Input, Text, BoxReset } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { useLostBoxesTool } from "./useLostBoxesTool";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const LostBoxesTool = () => {
  const { cdSkusController, onSubmitLostBoxesTool, resetAll, isSubmitting } = useLostBoxesTool();

  return (
    <Container>
      <Card>
        <StyledForm onSubmit={onSubmitLostBoxesTool}>
          <Text size="bodyLarge" bold>
            Lost Boxes Tool
          </Text>
          <Text size="body">Enter a comma-separated list of CDSKUs to submit.</Text>

          <Input
            {...cdSkusController.field}
            label="CDSKUs"
            helpText="Enter a comma-separated list of CDSKUs"
            required
            placeholder="CDSKU1234, CDSKU5678, CDSKU9012"
            hasError={!!cdSkusController.fieldState.error}
            errorMessage={cdSkusController.fieldState.error?.message}
          />

          <BoxReset marginTop="S4" marginBottom="S4">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button style={{ marginRight: "16px" }} secondary onClick={resetAll} loading={isSubmitting}>
                Cancel
              </Button>
              <Button type="submit" loading={isSubmitting}>
                Submit
              </Button>
            </div>
          </BoxReset>
        </StyledForm>
      </Card>
    </Container>
  );
};
